<template>
    <div class="form-input-container">
        <input
            :class="[
                'form-input-field',
                { 'form-input-field-invalid': phoneNumberInvalid || isRequiredField }
            ]"
            type="tel"
            id="phone"
            ref="phoneInput"
            v-maska="maska"
            :value="phoneNumber"
            @input="($e) => phoneNumber = $e.target.value"
            @countrychange="applyMask"
        >
    </div>
</template>

<script>
import intlTelInput from 'intl-tel-input';

export default {
    name: "VueTelInput",
    props: {
        number: {
            type: [String, Number],
            default: () => {
                return '';
            }
        },
        markAsRequiredField: {
            type: Boolean,
            required: false
        },
        markAsInvalidField: {
            type: Boolean,
            required: false
        }
    },
    emits: [
        "setPhoneNumber",
    ],
    data() {
        return {
            phoneNumber: '',
            intlTelInputInstance: null,
            maska: '',
            errorMap: [
                "Invalid phone number",
                "Invalid country code",
                "Phone number too short",
                "Phone number too long",
                "Invalid phone number",
                "Phone number too long"
            ],
            phoneNumberInvalid: false
        }
    },
    computed: {
        isRequiredField() {
            return this.markAsRequiredField && this.markAsInvalidField;
        }
    },
    watch: {
        phoneNumber(newVal) {
            if(!newVal.trim()) {
                this.$emit('setPhoneNumber', {
                    number: '',
                    isEmpty: true,
                    isValid: false
                });
            }
        },
    },
    methods: {
        resetError() {
            this.phoneNumberInvalid = false;
        },
        validatePhoneNumber() {
            this.resetError();
            if (this.phoneNumber.trim()) {
                let phoneNumber = {
                    number: '',
                    isValid: false,
                    isEmpty: false,
                };

                if (this.intlTelInputInstance.isValidNumber()) {
                    this.intlTelInputInstance.setNumber(
                        this.intlTelInputInstance.getNumber()
                    );
                    this.phoneNumberInvalid = false;
                    phoneNumber.number = this.intlTelInputInstance.getNumber();
                    phoneNumber.isValid = true;
                } else {
                    this.phoneNumberInvalid = true;
                    const errorCode = this.intlTelInputInstance.getValidationError();
                    if (this.errorMap[errorCode]) {
                        this.$store.dispatch(
                            'notifications/SHOW_ERROR_NOTIFICATION',
                            this.errorMap[errorCode]
                        );
                    } else {
                        this.$store.dispatch(
                            'notifications/SHOW_ERROR_NOTIFICATION',
                            this.errorMap[0]
                        );
                    }

                    phoneNumber.number = this.intlTelInputInstance.getNumber();
                    phoneNumber.isValid = false;
                }

                this.$emit('setPhoneNumber', phoneNumber);
            }
        },
        applyMask(isFirstApply) {
            // Get the selected country data to know which country is selected.
            const selectedCountryData = this.intlTelInputInstance.getSelectedCountryData();

            // Get an example number for the selected country to use as placeholder.
            let newPlaceholder = intlTelInputUtils.getExampleNumber(
                selectedCountryData.iso2,
                true,
                intlTelInputUtils.numberFormat.INTERNATIONAL
            );

            // Reset the phone number input.
            if (isFirstApply && typeof isFirstApply !== 'object') {
                this.intlTelInputInstance.setNumber(this.number)
                let currentNumber = this.intlTelInputInstance.getNumber();
                this.phoneNumber = currentNumber.replace(selectedCountryData.dialCode, '');
                this.validatePhoneNumber();
            } else {
                this.intlTelInputInstance.setNumber('');
                this.phoneNumber = "";
                this.phoneNumberInvalid = false;
            }

            // Convert placeholder as exploitable mask by replacing all 1-9 numbers with 0s
            // Apply the new mask for the input

            this.maska = newPlaceholder.replace(/[0-9]/g, "#");
        },
    },
    mounted() {
        this.phoneNumber = this.number;

        // on blur: validate
        this.$refs.phoneInput.addEventListener('blur', this.validatePhoneNumber, false);

        // on keyup / change flag: resetError
        this.$refs.phoneInput.addEventListener('change', this.resetError, false);
        this.$refs.phoneInput.addEventListener('keyup', this.resetError, false);

        this.intlTelInputInstance = intlTelInput(this.$refs.phoneInput, {
            initialCountry: "US",
            formatOnDisplay: true,
            nationalMode: true,
            separateDialCode: true,
            customPlaceholder: function() {
                return 'Your phone number'
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
        });

        // This line fixed getNumber in applyMask function.
        // Do not DELETE this duplicate code.
        this.intlTelInputInstance.setNumber(this.number);

        const vm = this;
        this.intlTelInputInstance.promise.then(function() {
            vm.intlTelInputInstance.setNumber(vm.number);
            vm.applyMask(true);
        });
    },
    beforeUnmount() {
        this.intlTelInputInstance.destroy();
        this.$refs.phoneInput.removeEventListener('blur', this.validatePhoneNumber, false);
        this.$refs.phoneInput.removeEventListener('change', this.resetError, false);
        this.$refs.phoneInput.removeEventListener('keyup', this.resetError, false);
    },
}
</script>

<style lang="scss">
@import '~intl-tel-input/build/css/intlTelInput.css';
</style>

<style lang="scss">
.iti {
    width: 100%;

    .form-input-field {
        width: 100%;
    }
}

.dark-theme {
    .iti {
        .iti__selected-dial-code,
        .form-input-field {
            color: #f7f7f7;
        }
    }
}
</style>
